import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1280.000000 1280.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)">
	<path d="M5915 11625 c-697 -60 -1302 -228 -1910 -533 -807 -403 -1540 -1070
-2020 -1837 -355 -567 -594 -1181 -711 -1825 -152 -835 -94 -1703 167 -2505
46 -144 113 -325 159 -430 11 -27 29 -68 38 -90 108 -257 311 -610 511 -890
71 -99 240 -316 260 -335 4 -3 52 -57 107 -120 720 -816 1766 -1400 2864
-1599 374 -68 476 -76 925 -76 435 0 511 6 865 66 1153 196 2233 808 2996
1699 521 608 880 1291 1079 2054 125 479 172 885 162 1409 -9 478 -65 858
-191 1302 -95 334 -175 540 -336 870 -587 1202 -1645 2135 -2918 2576 -644
223 -1396 319 -2047 264z m805 -550 c300 -37 496 -74 721 -135 792 -215 1496
-624 2069 -1199 173 -173 215 -219 311 -336 122 -149 104 -140 -31 15 -433
499 -931 883 -1525 1175 -320 157 -611 263 -940 344 -191 46 -257 59 -475 93
-205 32 -205 32 -620 32 -384 1 -426 -1 -565 -22 -577 -88 -955 -201 -1390
-417 -315 -157 -517 -284 -776 -490 -810 -644 -1342 -1501 -1543 -2485 -55
-271 -70 -431 -70 -745 0 -248 3 -312 22 -445 49 -335 107 -560 229 -885 31
-82 65 -163 74 -179 13 -22 15 -34 8 -48 -5 -10 -8 -22 -5 -26 17 -29 14 -67
-9 -94 -17 -20 -25 -24 -25 -14 0 9 -2 16 -4 16 -2 0 -11 3 -20 6 -12 5 -16 0
-16 -21 0 -21 -4 -26 -16 -21 -9 3 -25 6 -36 6 -14 0 -18 5 -14 15 3 8 1 15
-5 15 -6 0 -7 -14 -3 -35 8 -46 -1 -45 -16 3 -6 20 -15 46 -20 57 -10 21 -64
215 -98 350 -125 490 -156 1177 -77 1715 108 733 392 1413 845 2025 337 456
812 876 1320 1168 72 41 398 204 470 234 186 78 395 150 595 204 222 60 604
124 830 139 180 12 659 3 805 -15z m-230 -233 c0 -10 -17 -81 -39 -158 -108
-391 -106 -377 -52 -382 32 -3 33 -5 28 -39 -4 -20 -11 -41 -16 -46 -6 -6 -43
-12 -83 -14 -64 -4 -76 -2 -95 16 -16 15 -23 33 -23 58 0 29 135 550 145 561
3 4 90 19 118 21 10 1 17 -6 17 -17z m-284 -58 c-3 -25 -8 -49 -11 -54 -3 -5
-40 -11 -83 -14 -42 -4 -96 -9 -119 -12 l-41 -5 -11 -61 c-6 -33 -8 -64 -5
-69 2 -5 47 -6 99 -2 l95 6 -16 -46 c-9 -26 -21 -50 -27 -54 -6 -3 -46 -9 -89
-13 -43 -4 -81 -10 -85 -13 -3 -4 -11 -39 -18 -78 -10 -56 -10 -72 0 -75 7 -3
61 -1 121 4 60 5 112 7 115 6 6 -4 -7 -94 -15 -101 -6 -6 -387 -36 -392 -31
-5 6 96 612 104 620 6 5 267 32 361 37 23 1 23 -1 17 -45z m628 -41 c-13 -38
-32 -85 -41 -106 -9 -20 -14 -40 -11 -43 3 -3 25 0 49 6 91 25 169 -12 169
-79 -1 -20 -29 -110 -64 -201 l-63 -165 -61 0 c-33 0 -64 4 -69 8 -4 5 3 36
15 70 71 193 90 251 85 263 -9 24 -52 15 -88 -18 -28 -26 -46 -61 -85 -163
l-48 -130 -67 2 c-37 0 -68 3 -71 5 -3 4 177 482 219 581 l16 37 70 0 70 0
-25 -67z m636 -274 c44 -17 80 -36 80 -43 0 -7 -22 -53 -48 -102 -63 -118 -81
-160 -95 -225 l-12 -54 -58 -3 -57 -3 0 41 c0 22 -2 40 -5 40 -3 0 -22 -9 -42
-20 -65 -35 -161 -20 -197 31 -50 73 17 242 126 317 59 40 92 50 166 51 47 1
82 -6 142 -30z m389 -128 c48 -18 121 -67 121 -80 0 -4 -20 -22 -44 -39 l-44
-31 -28 25 c-49 42 -104 45 -104 5 0 -10 16 -38 35 -61 56 -66 58 -111 10
-168 -58 -69 -161 -82 -261 -32 -33 16 -70 41 -83 54 l-23 24 46 31 c24 17 48
31 52 31 3 0 24 -16 45 -35 38 -34 63 -42 85 -27 25 17 21 41 -17 92 -53 71
-57 90 -25 144 46 79 136 105 235 67z m348 -166 c47 -14 143 -85 143 -105 0
-5 -21 -21 -46 -36 l-45 -27 -38 37 c-27 27 -46 37 -62 34 -36 -5 -35 -38 2
-94 39 -56 42 -101 13 -141 -53 -71 -163 -94 -247 -52 -53 27 -127 86 -127
102 0 11 75 57 93 57 5 0 23 -16 42 -36 28 -29 40 -35 67 -32 44 4 49 36 13
84 -67 88 -42 172 62 210 49 17 69 17 130 -1z m424 -254 c25 -12 71 -45 103
-73 l57 -50 -119 -122 c-76 -78 -124 -134 -132 -159 -8 -20 -17 -37 -20 -37
-4 0 -30 7 -59 16 -55 16 -59 23 -37 76 7 14 1 16 -51 11 -68 -6 -105 8 -147
57 -40 49 -33 107 21 176 36 45 118 101 174 120 59 19 152 12 210 -15z m304
-231 c39 -17 46 -24 41 -40 -20 -65 -22 -60 30 -60 88 0 174 -62 174 -124 0
-42 -34 -77 -171 -180 -57 -42 -112 -84 -122 -92 -10 -8 -26 -12 -35 -9 -31
10 -96 71 -88 83 4 6 58 50 120 96 62 47 119 92 125 100 21 25 9 51 -24 54
-52 6 -100 -16 -210 -99 -95 -71 -110 -80 -129 -69 -22 12 -86 67 -86 74 0 2
44 36 98 76 114 86 178 144 198 183 17 32 19 33 79 7z m624 -345 c87 -88 151
-161 148 -168 -3 -7 -24 -19 -47 -26 l-43 -13 -46 46 c-26 25 -51 46 -57 46
-10 0 -161 -87 -422 -242 l-32 -20 -50 50 c-37 37 -46 52 -37 59 12 10 287
174 380 227 26 15 47 32 47 39 0 7 -18 31 -40 52 -22 21 -40 43 -40 47 0 7 69
56 81 57 3 1 74 -69 158 -154z m370 -89 c-3 -3 -11 5 -18 17 -13 21 -12 21 5
5 10 -10 16 -20 13 -22z m25 -22 c3 -8 2 -12 -4 -9 -6 3 -10 10 -10 16 0 14 7
11 14 -7z m-322 -278 c21 -10 56 -35 78 -56 86 -81 87 -160 4 -201 -77 -37
-161 -16 -268 66 -38 29 -50 34 -62 24 -27 -23 -15 -55 41 -108 30 -29 55 -55
55 -59 -1 -16 -53 -44 -73 -38 -33 8 -135 103 -157 147 -26 50 -25 103 1 140
24 34 96 79 154 96 56 17 181 11 227 -11z m-3488 -313 c57 -876 55 -793 21
-793 -22 0 -25 5 -25 33 0 98 -23 312 -40 375 -26 95 -42 124 -99 185 -99 108
-228 137 -598 137 -133 0 -248 -3 -256 -6 -12 -4 -9 -15 16 -56 l32 -51 5
-481 5 -481 125 0 c185 0 266 31 324 124 31 50 44 109 52 239 4 61 11 109 15
106 13 -8 11 -1061 -1 -1069 -6 -4 -10 29 -10 92 -1 206 -31 316 -103 378 -61
53 -118 67 -270 69 l-132 1 -5 -535 -5 -535 -29 -53 c-21 -38 -26 -56 -19 -63
8 -8 129 -10 400 -6 322 3 398 7 447 21 157 44 249 139 292 299 7 28 19 122
25 207 14 197 14 200 41 200 21 0 21 -2 15 -127 -4 -71 -13 -222 -21 -338 -8
-115 -18 -263 -22 -327 -7 -128 -24 -151 -24 -34 l0 73 -52 -14 c-43 -11 -200
-13 -823 -11 -509 2 -768 6 -765 13 3 6 39 10 80 11 132 1 140 2 171 33 l29
29 0 1116 c0 684 -4 1124 -10 1140 -17 45 -51 58 -170 64 -61 2 -110 9 -110
14 0 11 1416 5 1449 -7 l24 -8 -6 71 c-4 55 -3 72 7 72 9 0 15 -30 20 -107z
m3970 0 c25 -27 42 -51 38 -54 -4 -3 -117 -43 -252 -89 -135 -46 -252 -89
-260 -97 -12 -11 -12 -17 -2 -33 19 -30 15 -44 -18 -58 -34 -16 -55 -7 -92 36
-54 64 -60 125 -15 155 26 17 513 186 539 186 9 1 37 -20 62 -46z m234 -18
c-3 -3 -9 2 -12 12 -6 14 -5 15 5 6 7 -7 10 -15 7 -18z m142 -265 c31 -63 61
-126 65 -140 4 -14 11 -29 15 -35 24 -31 140 -358 195 -545 240 -828 236
-1753 -11 -2569 -39 -129 -38 -89 1 55 124 453 180 966 156 1427 -28 534 -112
956 -288 1447 -46 129 -181 439 -214 494 -10 17 -19 38 -19 46 0 16 18 -17
100 -180z m-453 -63 c121 -62 185 -200 121 -263 -65 -65 -195 -32 -285 72 -51
59 -79 68 -89 30 -7 -28 6 -55 53 -114 20 -24 34 -44 32 -46 -26 -19 -56 -36
-65 -36 -22 0 -95 85 -126 147 -41 83 -36 133 17 179 77 68 240 82 342 31z
m165 -429 c59 -25 132 -102 149 -156 14 -48 5 -112 -17 -112 -8 0 -33 9 -55
20 -33 17 -38 24 -33 44 15 60 -48 101 -141 94 -117 -10 -156 -52 -116 -128
26 -49 26 -56 4 -63 -10 -2 -25 -8 -34 -12 -21 -8 -53 24 -85 87 -33 66 -40
118 -20 160 42 88 218 121 348 66z m-3792 -273 c222 -5 445 -3 595 4 131 6
242 8 246 3 4 -4 -5 -38 -19 -75 -29 -79 -8 -74 -182 -38 -110 24 -281 38
-387 33 l-61 -3 -6 -72 c-19 -241 -32 -1214 -17 -1292 l8 -40 12 45 c43 155
101 266 195 375 172 197 511 309 843 276 65 -6 143 -17 173 -24 l55 -12 3 -64
c3 -60 1 -66 -29 -97 -19 -19 -43 -34 -56 -34 -32 0 -29 11 12 38 19 12 35 26
35 32 0 13 -147 58 -254 76 -64 12 -126 15 -211 11 -198 -8 -318 -59 -445
-186 -86 -86 -128 -162 -161 -288 -37 -143 -28 -349 22 -486 77 -211 264 -383
475 -437 174 -45 392 -9 555 92 56 34 59 34 59 4 0 -16 -23 -47 -72 -97 -40
-41 -91 -96 -113 -122 -32 -38 -44 -46 -62 -41 -13 4 -23 10 -23 14 0 5 24 32
53 60 58 57 57 58 -58 26 -145 -39 -382 -38 -506 4 -219 74 -394 240 -459 436
-21 62 -22 63 -38 45 -10 -12 -66 -35 -133 -55 -152 -47 -173 -47 -129 3 53
62 84 250 100 601 10 241 11 1006 1 1130 l-6 75 -145 3 c-149 3 -293 -9 -414
-34 -38 -8 -71 -12 -74 -9 -6 5 43 101 64 125 10 12 29 14 106 8 51 -3 253 -9
448 -13z m3908 -115 c67 -29 122 -91 145 -162 21 -67 21 -78 2 -126 -19 -48
-58 -71 -136 -82 -110 -16 -224 17 -286 82 -86 89 -98 235 -24 283 64 41 210
44 299 5z m-106 -506 c100 -14 225 -15 267 -3 9 2 21 -10 32 -31 28 -57 27
-73 -3 -88 -33 -16 -36 -32 -8 -50 54 -33 80 -161 40 -197 -20 -18 -27 -45
-12 -45 19 0 56 -86 56 -133 1 -64 -24 -87 -96 -87 -76 0 -352 41 -360 54 -4
6 -8 37 -8 70 l0 58 33 -5 c17 -3 92 -13 165 -23 118 -16 134 -16 148 -3 13
14 12 18 -5 41 -29 36 -58 45 -208 67 -73 11 -136 22 -142 25 -9 6 -27 124
-19 132 3 2 63 -5 134 -16 159 -24 212 -26 221 -4 9 24 -32 59 -90 76 -28 9
-98 22 -154 28 -56 7 -109 17 -117 24 -12 9 -22 77 -17 119 1 10 15 9 143 -9z
m6 -739 c39 -33 50 -77 27 -112 -15 -22 -22 -25 -53 -20 -43 7 -92 58 -92 96
0 60 66 80 118 36z m217 -339 c188 -89 279 -237 245 -400 -12 -60 -50 -116
-78 -116 -10 0 -29 21 -44 48 l-27 48 27 27 c22 22 27 36 26 73 -1 38 -7 51
-40 85 -60 61 -195 119 -280 119 -71 0 -104 -56 -90 -156 l6 -44 -49 0 -49 0
-7 60 c-14 124 32 262 98 290 12 6 58 9 103 7 69 -3 92 -9 159 -41z m-201
-441 c163 -77 237 -237 176 -379 -39 -91 -119 -107 -237 -47 -117 60 -183 155
-183 266 1 159 104 226 244 160z m-7975 -165 c11 -24 11 -24 -3 -6 -9 11 -16
24 -16 30 0 12 5 7 19 -24z m-12 -52 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7
2 -19 0 -25z m7753 -240 c115 -87 207 -145 253 -157 51 -15 56 -26 41 -100 -6
-30 -9 -32 -43 -29 -31 2 -36 0 -33 -17 15 -95 14 -125 -7 -169 -17 -34 -29
-46 -51 -51 -29 -6 -30 -7 -23 -65 5 -48 2 -66 -17 -104 -39 -81 -105 -72
-230 31 -8 6 -52 39 -97 72 -45 33 -84 65 -87 70 -8 13 34 121 47 121 6 0 60
-38 121 -83 61 -46 122 -89 134 -96 67 -36 68 46 2 110 -8 8 -61 50 -118 93
-79 59 -102 82 -97 94 4 10 12 31 17 47 6 17 16 36 23 44 15 15 11 18 178
-106 108 -80 137 -90 137 -45 0 43 -42 94 -130 158 -45 32 -95 70 -111 84
l-29 25 23 57 c12 32 27 58 32 58 6 0 35 -19 65 -42z m710 -63 c-7 -20 -14
-34 -17 -31 -3 2 1 20 8 40 17 46 25 38 9 -9z m-3040 -56 l0 -82 38 7 c87 13
151 -31 178 -122 30 -102 -15 -233 -94 -272 -34 -16 -50 -40 -27 -40 7 0 16
-4 19 -9 3 -5 -20 -7 -52 -5 -46 4 -60 1 -71 -13 -13 -17 -13 -17 -8 0 6 15 1
17 -34 14 -22 -2 -45 -10 -50 -18 -7 -11 -9 -11 -9 4 0 12 10 20 30 24 24 4
28 8 19 19 -6 8 -19 14 -28 14 -24 0 -41 20 -41 48 0 13 -7 30 -15 38 -13 14
-15 13 -16 -3 0 -17 -1 -16 -8 1 -9 25 -17 13 -26 -41 l-7 -42 -131 1 -132 1
-32 32 c-22 22 -33 43 -33 62 0 15 -5 35 -10 43 -8 13 -10 12 -10 -3 0 -28
-48 -96 -83 -117 -39 -24 -113 -26 -184 -5 -43 13 -53 21 -62 47 -15 44 -2 55
47 40 61 -18 95 -15 114 13 20 26 29 15 -82 105 -56 45 -80 86 -80 135 0 57
31 102 87 127 50 22 103 21 178 -4 36 -12 40 -17 43 -51 3 -37 2 -38 -25 -31
-15 3 -43 10 -61 16 -26 8 -38 6 -57 -6 -14 -9 -25 -19 -25 -22 0 -18 26 -46
70 -74 27 -18 66 -51 85 -74 l35 -41 0 87 c0 101 4 106 97 115 45 5 57 3 64
-10 8 -14 14 -14 50 -1 23 7 66 14 95 14 l54 0 0 59 0 59 43 16 c52 19 73 23
115 25 l32 1 0 -81z m916 40 c8 -6 14 -22 14 -36 l0 -24 100 7 100 7 0 -41 c0
-50 9 -53 97 -41 119 17 228 14 265 -8 46 -27 78 -78 78 -124 0 -48 -12 -54
-112 -64 -68 -6 -78 -9 -78 -26 0 -11 11 -31 25 -44 20 -21 34 -25 85 -25 55
0 61 -2 72 -25 9 -20 9 -28 0 -37 -19 -19 -92 -38 -150 -38 -92 0 -149 39
-173 119 -10 34 -13 36 -52 35 -35 0 -43 -4 -45 -22 -3 -18 3 -24 30 -29 18
-4 36 -6 41 -5 4 1 7 -14 7 -33 0 -41 -18 -53 -89 -62 -86 -10 -131 13 -131
68 0 27 -3 29 -35 29 -31 0 -35 -3 -35 -27 0 -68 -7 -74 -74 -71 l-61 3 -3 35
c-2 22 -10 40 -22 48 -12 7 -20 8 -20 2 0 -39 -95 -90 -168 -90 -59 1 -132 17
-152 34 -8 8 -31 16 -52 20 -35 6 -57 29 -38 41 4 3 41 6 80 7 56 2 74 6 81
20 6 11 24 18 52 20 l42 3 -65 47 c-64 46 -100 90 -100 123 0 12 -8 16 -27 15
-59 -4 -63 -2 -63 34 0 38 29 80 68 100 38 18 175 40 207 32 16 -4 36 -1 53 9
59 33 214 42 248 14z m-3001 -48 c47 -21 61 -36 70 -74 6 -23 4 -29 -14 -34
-14 -3 -21 -13 -21 -29 0 -13 -6 -33 -12 -44 -11 -18 -9 -23 17 -45 17 -13 34
-23 39 -22 5 2 14 21 21 43 27 92 89 138 185 138 76 0 130 -30 155 -83 18 -41
18 -42 -3 -62 -12 -11 -22 -26 -22 -33 0 -17 -31 -26 -88 -26 -48 0 -61 -15
-42 -50 15 -28 68 -50 120 -50 32 0 40 -4 40 -18 0 -62 -180 -106 -266 -66
-20 10 -51 40 -72 70 -20 30 -41 52 -47 51 -5 -2 -12 -18 -16 -35 -16 -76 -73
-101 -230 -102 -98 0 -118 3 -123 16 -8 21 -8 459 1 467 3 4 65 7 137 7 105 0
139 -4 171 -19z m5102 -3 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z
m-6848 1 c65 -9 88 -9 107 1 19 11 46 10 141 -1 112 -13 118 -15 115 -36 -3
-19 3 -22 55 -27 32 -4 85 -11 119 -17 42 -7 73 -7 100 0 53 13 589 14 632 0
24 -8 32 -16 32 -34 0 -39 -10 -57 -26 -44 -17 14 -54 2 -54 -17 0 -8 -9 -22
-19 -31 -16 -13 -18 -25 -14 -68 3 -29 3 -69 -1 -88 -6 -33 -7 -34 -49 -31
-30 3 -38 1 -27 -6 9 -6 10 -10 3 -10 -6 0 -13 -12 -15 -27 -3 -27 -6 -28 -62
-31 -62 -3 -78 4 -83 40 -6 37 -23 46 -23 11 0 -17 -6 -35 -13 -41 -7 -6 -63
-10 -136 -10 l-123 0 -39 35 c-35 32 -39 40 -39 83 0 29 -6 52 -14 59 -12 10
-17 2 -32 -41 -9 -29 -26 -65 -37 -80 -24 -32 -93 -58 -153 -58 -48 0 -129 31
-153 59 -14 16 -24 18 -61 11 -25 -5 -48 -6 -52 -4 -4 2 -8 -10 -10 -28 l-3
-33 -65 0 -65 0 -5 100 -5 100 -67 128 c-38 70 -68 132 -68 138 0 12 108 11
209 -2z m2691 -38 c0 -39 0 -40 46 -47 43 -6 45 -8 42 -38 -3 -28 -7 -31 -43
-36 l-40 -5 -3 -90 c-3 -97 5 -115 54 -115 25 0 39 -28 29 -58 -6 -22 -65 -42
-122 -42 -30 0 -47 7 -67 26 -25 26 -26 30 -26 150 0 113 -2 124 -20 134 -13
7 -20 21 -20 39 0 42 89 121 136 121 33 0 34 -1 34 -39z m-226 -42 c21 -10 26
-19 26 -47 0 -32 -2 -34 -27 -28 -65 15 -101 18 -107 8 -11 -18 3 -36 49 -65
68 -43 95 -81 95 -131 0 -50 -25 -88 -73 -112 -24 -13 -48 -15 -102 -11 -98 8
-107 12 -103 56 3 34 5 36 38 35 19 0 51 -2 70 -5 55 -7 51 22 -10 61 -93 60
-122 122 -89 194 19 42 84 68 150 62 32 -3 69 -11 83 -17z m-3685 -90 c18 -22
31 -42 29 -45 -3 -2 -19 16 -37 41 -40 56 -35 59 8 4z m-106 -113 c30 -43 45
-67 59 -96 17 -35 60 -85 70 -83 4 2 7 0 6 -5 -5 -18 15 -71 28 -76 9 -3 12
-11 9 -17 -4 -6 2 -20 13 -32 11 -12 23 -32 26 -43 4 -12 16 -24 26 -27 11 -3
20 -13 20 -24 0 -10 12 -29 25 -42 28 -26 35 -51 8 -30 -15 12 -16 12 -8 -1
20 -35 28 -42 36 -28 6 10 12 8 23 -7 21 -27 20 -40 -1 -24 -17 13 -17 13 0
-7 17 -20 23 -44 11 -44 -3 0 -15 10 -25 23 l-20 22 17 -28 c11 -17 22 -26 33
-22 10 3 24 -7 40 -31 40 -58 230 -245 322 -316 286 -223 629 -381 1209 -558
58 -17 130 -40 160 -50 30 -10 111 -35 180 -55 175 -53 448 -143 468 -155 31
-18 0 -10 -117 29 -64 22 -170 56 -236 76 -66 20 -156 47 -200 60 -44 14 -141
42 -215 64 -577 170 -916 323 -1215 546 -60 46 -145 117 -189 159 -90 88 -116
94 -35 9 108 -115 299 -269 446 -360 139 -87 467 -245 628 -303 28 -11 88 -33
135 -50 75 -28 229 -79 738 -246 78 -26 140 -49 138 -52 -5 -4 -188 53 -636
199 -462 150 -696 251 -973 416 -178 107 -289 194 -476 377 -94 92 -115 107
-72 52 45 -56 238 -242 321 -308 371 -294 771 -481 1655 -775 94 -31 175 -60
180 -65 6 -6 4 -7 -5 -4 -326 105 -590 193 -695 231 -424 154 -754 317 -1008
497 -69 49 -215 164 -248 197 -9 9 -52 50 -94 91 -105 102 -79 57 32 -54 343
-344 803 -617 1448 -857 88 -33 198 -73 245 -90 47 -17 98 -36 114 -42 16 -6
25 -12 19 -14 -10 -4 -228 72 -443 153 -293 110 -541 224 -755 347 -196 113
-467 316 -624 470 -90 87 -100 84 -15 -5 384 -400 956 -740 1714 -1016 252
-92 275 -101 263 -101 -6 -1 -44 10 -85 24 -40 14 -119 41 -176 61 -56 19
-117 41 -135 49 -17 7 -99 41 -182 74 -573 229 -960 469 -1360 841 -89 83 -55
39 50 -63 336 -330 748 -603 1270 -842 88 -41 349 -146 407 -164 21 -7 40 -16
43 -21 9 -14 -63 10 -245 83 -494 196 -892 422 -1231 697 -93 75 -202 170
-234 204 -17 17 -39 37 -50 45 -11 8 21 -27 70 -78 161 -166 396 -358 635
-518 103 -69 238 -153 246 -153 2 0 32 -17 66 -38 133 -81 513 -259 687 -323
35 -12 60 -24 54 -26 -19 -7 -405 154 -583 243 -102 50 -354 196 -465 268
-210 137 -408 294 -608 480 -122 113 -125 115 -57 41 30 -33 109 -110 175
-172 374 -347 793 -627 1260 -839 61 -28 121 -56 135 -62 l25 -12 -28 5 c-15
3 -52 17 -82 30 -29 14 -56 25 -59 25 -4 0 -74 34 -156 75 -614 305 -1049 647
-1529 1200 -119 137 -373 498 -437 620 -6 11 -29 52 -51 90 -59 103 -116 225
-109 233 9 8 26 -18 26 -40 0 -9 5 -20 11 -24 9 -5 10 3 5 29 -4 20 -2 38 3
41 5 4 8 16 6 29 -4 21 -4 21 10 3 14 -18 15 -18 42 11 34 38 49 37 76 -1z
m2380 -215 c9 -1 17 -6 17 -12 0 -5 -4 -8 -9 -4 -5 3 -12 1 -16 -5 -3 -5 -12
-10 -18 -10 -7 0 -6 4 3 10 11 7 6 10 -20 10 -24 0 -31 3 -21 9 7 5 21 7 30 6
9 -2 24 -4 34 -4z m-1096 -23 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1
-19z m83 7 c0 -13 -4 -14 -20 -5 -11 6 -25 8 -30 5 -5 -3 -11 -1 -15 5 -4 6 8
10 29 10 26 0 36 -4 36 -15z m280 6 c0 -5 -11 -11 -25 -13 -14 -3 -25 -3 -25
-1 0 3 34 19 48 22 1 1 2 -3 2 -8z m68 2 c-10 -2 -28 -2 -40 0 -13 2 -5 4 17
4 22 1 32 -1 23 -4z m305 0 c-13 -2 -23 -9 -23 -14 0 -5 -8 -9 -17 -9 -16 1
-16 2 1 15 11 8 29 14 40 14 19 -2 19 -2 -1 -6z m207 -3 c0 -15 -40 -22 -101
-17 -54 3 -54 3 16 7 68 3 68 3 25 10 l-45 7 53 1 c28 1 52 -2 52 -8z m773 3
c3 -5 -26 -6 -64 -5 -48 3 -75 -1 -86 -10 -11 -8 -14 -8 -10 -1 5 8 -5 13 -30
16 -21 2 12 4 74 5 61 1 114 -1 116 -5z m360 0 c-7 -2 -21 -2 -30 0 -10 3 -4
5 12 5 17 0 24 -2 18 -5z m324 0 c-20 -2 -35 -8 -32 -14 4 -5 -1 -9 -9 -9 -10
0 -13 6 -10 15 4 10 19 14 48 13 l41 -1 -38 -4z m349 -8 c17 -13 17 -14 2 -15
-10 0 -18 4 -18 9 0 6 -15 12 -32 14 l-33 4 31 1 c16 1 39 -5 50 -13z m437 8
c-13 -2 -35 -2 -50 0 -16 2 -5 4 22 4 28 0 40 -2 28 -4z m305 -10 c2 -8 -5
-13 -20 -12 -22 0 -22 1 -3 9 19 8 19 9 -2 9 -12 1 -25 -3 -28 -9 -3 -5 -11
-10 -17 -10 -6 0 -6 6 2 16 15 17 61 15 68 -3z m172 7 c0 -13 -41 -22 -81 -17
-28 3 -26 4 16 7 l50 3 -45 7 -45 7 53 1 c28 1 52 -2 52 -8z m823 3 c-7 -2
-21 -2 -30 0 -10 3 -4 5 12 5 17 0 24 -2 18 -5z m297 -3 c0 -23 -100 -26 -108
-2 -3 8 12 12 52 12 31 0 56 -4 56 -10z m203 3 c-7 -2 -21 -2 -30 0 -10 3 -4
5 12 5 17 0 24 -2 18 -5z m310 0 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19
-2 13 -5z m-353 -19 c0 -2 -7 -4 -15 -4 -8 0 -15 4 -15 10 0 5 7 7 15 4 8 -4
15 -8 15 -10z m-4537 -1 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z
m364 1 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m280 0 c-3 -3
-12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m1030 0 c-3 -3 -12 -4 -19 -1
-8 3 -5 6 6 6 11 1 17 -2 13 -5z m608 -4 c-3 -5 -12 -10 -18 -10 -7 0 -6 4 3
10 19 12 23 12 15 0z m482 4 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2
13 -5z m1240 0 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m56 -1
c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m530 0 c-7 -2 -21 -2
-30 0 -10 3 -4 5 12 5 17 0 24 -2 18 -5z m67 -3 c0 -5 -4 -10 -10 -10 -5 0
-10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m255 5 c-23 -8 -83 -10 -79 -3
3 4 24 8 47 7 23 0 38 -2 32 -4z m52 -1 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6
11 1 17 -2 13 -5z m-6412 -104 c3 -5 2 -10 -4 -10 -5 0 -13 5 -16 10 -3 6 -2
10 4 10 5 0 13 -4 16 -10z m49 -66 c3 -8 2 -12 -4 -9 -6 3 -10 10 -10 16 0 14
7 11 14 -7z m106 -79 c13 -14 21 -25 18 -25 -2 0 -15 11 -28 25 -13 14 -21 25
-18 25 2 0 15 -11 28 -25z m-10 -37 c0 -6 -7 -5 -15 2 -8 7 -15 17 -15 22 0 6
7 5 15 -2 8 -7 15 -17 15 -22z m79 -93 c8 -11 -4 0 -27 24 -23 24 -42 48 -42
53 0 9 42 -38 69 -77z m41 7 c0 -3 -16 9 -35 28 -19 19 -35 38 -35 43 0 4 16
-8 35 -29 19 -20 35 -39 35 -42z m-1 44 c-3 -3 -11 5 -18 17 -13 21 -12 21 5
5 10 -10 16 -20 13 -22z m-56 -67 c38 -43 64 -79 59 -79 -6 0 -34 28 -64 63
-29 34 -56 64 -61 65 -4 2 -12 12 -17 23 -21 38 15 7 83 -72z m87 41 c0 -5 -2
-10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m755 -24 c16
-7 -59 -7 -109 0 -26 4 -42 10 -36 14 10 6 122 -5 145 -14z m-724 -3 c13 -16
12 -17 -3 -4 -17 13 -22 21 -14 21 2 0 10 -8 17 -17z m-11 -38 c19 -19 32 -35
29 -35 -3 0 -20 16 -39 35 -19 19 -32 35 -29 35 3 0 20 -16 39 -35z m913 28
c-7 -2 -21 -2 -30 0 -10 3 -4 5 12 5 17 0 24 -2 18 -5z m-858 -23 c3 -5 1 -10
-4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m1653 -10 c27 -8 27
-8 2 -8 -14 0 -81 6 -150 13 l-125 14 123 -5 c67 -3 134 -9 150 -14z m-1724
-25 c21 -19 34 -35 29 -35 -5 0 -24 16 -43 35 -19 19 -32 35 -29 35 3 0 22
-16 43 -35z m231 25 c3 -6 -1 -7 -9 -4 -18 7 -21 14 -7 14 6 0 13 -4 16 -10z
m318 3 c-7 -2 -21 -2 -30 0 -10 3 -4 5 12 5 17 0 24 -2 18 -5z m44 -9 c-3 -3
-12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m1200 -10 c-3 -3 -12 -4 -19
-1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-1712 -34 c10 -11 16 -20 13 -20 -3 0
-13 9 -23 20 -10 11 -16 20 -13 20 3 0 13 -9 23 -20z m75 -12 c0 -12 -6 -9
-26 13 -19 22 -19 22 4 8 12 -8 22 -17 22 -21z m64 17 c11 -8 15 -15 10 -15
-6 0 -19 7 -30 15 -10 8 -14 14 -9 14 6 0 19 -6 29 -14z m125 -11 c14 -12 -19
-1 -35 12 -18 14 -18 14 6 3 14 -6 27 -13 29 -15z m131 16 c8 -5 11 -10 5 -10
-5 0 -17 5 -25 10 -8 5 -10 10 -5 10 6 0 17 -5 25 -10z m317 4 c-3 -3 -12 -4
-19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m1192 -10 c78 -23 57 -26 -29 -5 -41
10 -63 19 -49 20 14 1 49 -6 78 -15z m-924 -4 l50 -7 -60 0 c-33 0 -94 3 -135
7 l-75 7 85 1 c47 0 108 -3 135 -8z m142 -16 c-3 -3 -12 -4 -19 -1 -8 3 -5 6
6 6 11 1 17 -2 13 -5z m-1176 -30 c40 -43 189 -158 279 -216 262 -167 560
-281 1190 -454 322 -88 677 -200 730 -231 29 -16 32 -17 -135 39 -106 35 -304
96 -360 111 -11 2 -69 18 -130 35 -60 17 -144 40 -185 51 -515 138 -831 259
-1090 421 -115 71 -360 270 -333 270 5 0 20 -12 34 -26z m87 -27 c200 -160
422 -271 756 -377 114 -36 258 -74 721 -192 266 -67 541 -152 655 -202 54 -24
23 -19 -50 8 -36 13 -108 37 -160 54 -84 26 -138 42 -335 97 -27 7 -162 41
-300 75 -371 91 -571 149 -745 217 -110 43 -291 133 -365 182 -38 25 -75 48
-81 51 -18 8 -178 140 -170 140 4 0 37 -24 74 -53z m2055 22 c81 -28 155 -61
276 -124 43 -23 166 -105 156 -105 -4 0 -48 26 -98 57 -111 68 -221 120 -348
163 -51 18 -96 34 -98 36 -13 11 31 0 112 -27z m-2013 -11 c0 -12 -6 -9 -26
13 -19 22 -19 22 4 8 12 -8 22 -17 22 -21z m76 1 c3 -6 -5 -3 -20 6 -14 10
-26 19 -26 22 0 7 39 -17 46 -28z m78 16 c11 -8 15 -15 10 -15 -6 0 -19 7 -30
15 -10 8 -14 14 -9 14 6 0 19 -6 29 -14z m155 -1 c13 -11 -8 -10 -34 2 l-30
13 30 -6 c17 -3 32 -7 34 -9z m286 -5 c25 -11 -68 0 -105 12 -16 6 -5 6 30 3
30 -4 64 -10 75 -15z m935 1 c39 -11 -56 -5 -165 11 -49 6 -43 7 35 3 52 -3
111 -9 130 -14z m88 -17 c-10 -2 -26 -2 -35 0 -10 3 -2 5 17 5 19 0 27 -2 18
-5z m-1563 -29 c17 -14 21 -21 10 -15 -30 14 -61 40 -50 41 6 0 24 -12 40 -26z
m418 0 l42 -14 -40 6 c-22 3 -53 12 -70 19 l-30 14 28 -6 c16 -3 47 -12 70
-19z m1317 1 c140 -34 263 -77 388 -136 109 -51 226 -118 219 -125 -2 -3 -33
13 -68 34 -158 96 -361 173 -589 226 -58 13 -94 25 -80 25 14 1 72 -10 130
-24z m-1646 0 c11 -8 15 -15 10 -15 -6 0 -19 7 -30 15 -10 8 -14 14 -9 14 6 0
19 -6 29 -14z m146 -11 c0 -9 -28 -3 -46 11 -17 13 -16 14 14 4 17 -6 32 -13
32 -15z m850 2 c74 -8 116 -14 92 -15 -56 -1 -405 29 -412 36 -5 6 110 -2 320
-21z m-587 -13 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m-18
-201 c157 -52 336 -98 720 -183 320 -71 327 -72 451 -107 196 -55 431 -138
421 -149 -3 -2 -29 6 -58 18 -180 71 -449 143 -959 254 -425 94 -699 181 -910
292 -146 76 -120 75 36 -2 83 -41 216 -96 299 -123z m-115 169 l25 -11 -27 5
c-34 6 -78 23 -78 29 0 6 45 -8 80 -23z m291 9 c58 -10 168 -26 245 -35 473
-53 710 -96 952 -170 81 -25 57 -28 -29 -4 -200 56 -475 109 -729 139 -261 30
-483 62 -522 75 -61 19 -39 18 83 -5z m793 -7 c60 -9 104 -19 97 -21 -16 -5
-260 28 -286 40 -24 10 37 4 189 -19z m-1244 -4 c26 -15 29 -18 10 -13 -35 9
-78 33 -60 33 8 0 31 -9 50 -20z m156 -67 c-6 -6 -96 26 -111 39 -5 5 19 0 54
-12 34 -12 60 -24 57 -27z m174 26 c52 -12 89 -23 82 -26 -13 -4 -192 37 -192
44 0 7 12 5 110 -18z m1201 -12 c74 -19 180 -51 234 -71 103 -39 308 -136 335
-160 8 -7 29 -20 45 -28 17 -9 41 -26 55 -38 32 -27 11 -16 -70 37 -96 62
-380 194 -418 194 -7 0 -44 11 -84 24 -40 14 -117 34 -170 46 -54 12 -98 24
-98 26 0 10 43 2 171 -30z m-1275 -41 c54 -20 60 -24 29 -19 -39 6 -130 42
-109 43 5 1 41 -10 80 -24z m264 15 c25 -5 162 -28 305 -50 481 -75 761 -139
955 -218 140 -57 338 -151 317 -152 -4 0 -55 23 -115 51 -284 136 -514 198
-1067 289 -323 52 -508 88 -465 89 14 0 45 -4 70 -9z m-72 -64 c53 -13 160
-36 237 -50 366 -69 613 -121 750 -156 155 -40 367 -110 414 -135 45 -24 5
-16 -74 15 -89 35 -255 87 -344 106 -35 8 -66 16 -70 18 -8 5 -329 71 -596
121 -204 39 -375 78 -415 94 -44 17 -1 12 98 -13z m1797 -26 c3 -5 1 -10 -4
-10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m32 -134 c4 -5 -22 10
-56 34 -34 24 -65 48 -68 54 -4 5 22 -10 56 -34 34 -24 65 -48 68 -54z m-464
50 c71 -29 163 -73 132 -64 -20 6 -230 90 -234 94 -13 12 28 0 102 -30z m518
7 c13 -16 12 -17 -3 -4 -10 7 -18 15 -18 17 0 8 8 3 21 -13z m84 -73 c10 -11
16 -20 13 -20 -3 0 -13 9 -23 20 -10 11 -16 20 -13 20 3 0 13 -9 23 -20z
m-410 -20 c39 -26 25 -26 -17 0 -18 11 -28 20 -23 20 6 0 24 -9 40 -20z m310
10 c3 -6 -1 -7 -9 -4 -18 7 -21 14 -7 14 6 0 13 -4 16 -10z m-170 -89 c17 -12
27 -21 23 -21 -9 0 -128 71 -128 76 0 6 68 -30 105 -55z m105 34 c11 -13 10
-14 -4 -9 -9 3 -16 10 -16 15 0 13 6 11 20 -6z m75 -55 c3 -5 1 -10 -4 -10 -6
0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m-85 -41 c17 -13 18 -17 5 -10
-33 17 -51 31 -40 31 6 0 21 -10 35 -21z m-205 -87 c108 -55 85 -53 -31 3 -51
25 -97 45 -103 45 -5 0 -13 4 -16 10 -9 14 61 -13 150 -58z m133 35 c25 -20 4
-18 -29 3 -28 18 -29 20 -9 15 14 -4 31 -12 38 -18z m257 5 c-3 -3 -11 0 -18
7 -9 10 -8 11 6 5 10 -3 15 -9 12 -12z m-100 -12 c3 -5 1 -10 -4 -10 -6 0 -11
5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m-70 -55 l20 -16 -20 10 c-11 5 -29
17 -40 26 l-20 16 20 -10 c11 -5 29 -17 40 -26z m-302 -42 c15 -7 27 -15 27
-18 0 -5 -92 34 -100 42 -8 8 41 -7 73 -24z m203 1 c18 -14 18 -14 -6 -3 -31
14 -36 19 -24 19 6 0 19 -7 30 -16z m159 -2 c-3 -3 -11 0 -18 7 -9 10 -8 11 6
5 10 -3 15 -9 12 -12z m-274 -38 c17 -8 29 -18 26 -20 -6 -6 -67 23 -67 31 0
8 10 6 41 -11z m194 -4 c3 -6 -1 -7 -9 -4 -18 7 -21 14 -7 14 6 0 13 -4 16
-10z m-318 -45 c20 -8 34 -18 30 -21 -3 -3 -22 4 -44 15 -45 24 -37 27 14 6z
m234 -13 c-7 -2 -18 1 -23 6 -8 8 -4 9 13 5 13 -4 18 -8 10 -11z m-111 -42
c19 -11 31 -19 27 -20 -12 0 -77 28 -77 34 0 10 14 6 50 -14z m195 0 c17 -11
26 -20 20 -20 -5 0 -23 9 -40 20 -16 11 -25 20 -20 20 6 0 24 -9 40 -20z
m-335 -46 c23 -9 39 -18 37 -21 -3 -2 -26 5 -53 16 -27 11 -43 20 -37 20 7 1
31 -6 53 -15z m240 -4 c19 -11 31 -19 25 -19 -5 0 -26 8 -45 19 -19 11 -30 19
-25 19 6 0 26 -8 45 -19z m3690 -4 c0 -2 -7 -7 -16 -10 -8 -3 -12 -2 -9 4 6
10 25 14 25 6z m-3790 -53 l25 -14 -25 7 c-14 4 -36 13 -50 21 l-25 14 25 -7
c14 -4 36 -13 50 -21z m220 -9 c0 -2 -13 2 -30 11 -16 9 -30 18 -30 21 0 2 14
-2 30 -11 17 -9 30 -18 30 -21z m3449 -27 c-46 -35 -113 -83 -149 -107 -36
-24 -66 -47 -68 -52 -2 -4 -8 -8 -14 -8 -5 0 -24 -10 -41 -22 -17 -13 -39 -27
-49 -33 -176 -104 -159 -95 -327 -182 -300 -156 -705 -302 -1006 -364 -33 -7
-67 -13 -75 -14 -9 0 -20 -4 -25 -7 -18 -10 -176 -31 -183 -24 -11 12 18 23
76 30 49 6 199 38 241 52 39 13 -8 12 -114 -1 -116 -14 -144 -13 -115 5 8 5
15 15 15 22 0 7 9 11 20 10 33 -6 9 10 -27 17 -24 5 -2 9 80 15 61 4 129 13
150 20 l37 13 -69 8 c-67 7 -144 3 -179 -10 -13 -5 -16 -3 -11 8 4 11 -2 17
-18 21 -13 2 9 4 47 2 39 -2 79 1 90 5 15 5 8 8 -25 9 -39 0 -43 2 -28 14 9 7
34 11 54 10 35 -3 36 -2 20 15 -17 19 -22 36 -8 27 5 -3 9 1 9 9 0 8 -4 12 -9
9 -4 -3 -8 5 -8 16 0 16 4 20 16 16 9 -3 20 -6 25 -6 5 0 9 -9 9 -20 0 -11 4
-20 10 -20 5 0 7 6 5 13 -4 9 42 12 212 12 165 0 242 4 318 18 120 21 310 70
374 96 25 11 75 30 111 44 76 29 176 73 205 89 11 6 43 24 70 38 127 68 398
240 411 261 3 5 10 9 16 9 6 0 -27 -28 -73 -63z m-3799 -13 c16 -8 19 -13 9
-14 -8 0 -33 9 -55 20 -35 18 -36 19 -9 14 17 -4 41 -13 55 -20z m225 6 c3 -5
-4 -6 -15 -3 -11 3 -22 9 -25 13 -3 5 4 6 15 3 11 -3 22 -9 25 -13z m196 -7
c13 -17 13 -17 -6 -3 -11 8 -22 16 -24 17 -2 2 0 3 6 3 6 0 17 -8 24 -17z
m-588 -44 c34 -11 66 -25 72 -30 5 -5 -24 2 -64 16 -68 23 -95 35 -77 35 3 0
35 -9 69 -21z m287 -5 l25 -15 -25 7 c-14 3 -29 10 -34 15 -16 14 6 10 34 -7z
m237 -10 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-367 -46 c10
-6 7 -7 -10 -2 -14 3 -32 11 -40 16 -10 6 -7 7 10 2 14 -3 32 -11 40 -16z
m245 8 c19 -8 35 -17 35 -20 0 -5 -71 22 -79 30 -9 8 11 4 44 -10z m190 -4
c-3 -3 -11 0 -18 7 -9 10 -8 11 6 5 10 -3 15 -9 12 -12z m-575 -59 l25 -14
-25 7 c-14 4 -41 13 -60 20 l-35 14 35 -6 c19 -4 46 -13 60 -21z m275 7 c8
-13 -5 -13 -25 0 -13 8 -13 10 2 10 9 0 20 -4 23 -10z m222 4 c-3 -3 -12 -4
-19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-677 -53 c47 -16 80 -30 74 -30 -13
-1 -166 48 -173 55 -10 10 20 2 99 -25z m310 -3 c10 -6 7 -7 -10 -2 -14 3 -32
11 -40 16 -10 6 -7 7 10 2 14 -3 32 -11 40 -16z m245 4 c-3 -3 -11 0 -18 7 -9
10 -8 11 6 5 10 -3 15 -9 12 -12z m-405 -54 c10 -6 6 -7 -15 -3 -16 4 -34 11
-40 17 -12 12 31 1 55 -14z m287 6 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1
17 -2 13 -5z m-160 -50 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z
m-132 -62 c-5 -5 -77 18 -89 29 -5 4 14 1 42 -8 29 -9 50 -19 47 -21z m-184
-38 c53 -17 67 -29 22 -18 -33 8 -92 33 -80 34 5 0 30 -7 58 -16z m299 6 c11
-8 7 -9 -15 -4 -37 8 -45 14 -19 14 10 0 26 -5 34 -10z m60 -20 c18 -12 2 -12
-25 0 -13 6 -15 9 -5 9 8 0 22 -4 30 -9z m-235 -30 c8 -13 -5 -13 -25 0 -13 8
-13 10 2 10 9 0 20 -4 23 -10z m42 -16 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11
1 17 -2 13 -5z m-167 -45 c31 -13 36 -18 16 -14 -37 8 -95 33 -76 34 8 0 35
-9 60 -20z m2430 7 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6
0 10 -2 10 -4z m-2543 -72 c-5 -6 -107 29 -107 37 0 4 25 -2 55 -14 31 -11 54
-21 52 -23z m63 -19 c19 -7 28 -14 20 -14 -8 0 -28 6 -45 14 -39 17 -21 18 25
0z m77 -31 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z"/>
<path d="M7300 10403 c-51 -19 -134 -150 -128 -201 5 -45 48 -42 96 6 43 43
113 165 103 181 -8 12 -52 21 -71 14z"/>
<path d="M8480 9831 c-58 -32 -132 -115 -128 -142 2 -19 10 -25 38 -27 41 -3
131 50 190 112 31 32 33 38 19 51 -25 25 -79 28 -119 6z"/>
<path d="M9508 8859 c-24 -13 -23 -24 5 -42 41 -27 81 -30 108 -8 23 19 24 19
5 40 -21 23 -87 29 -118 10z"/>
<path d="M9930 8201 c-21 -15 -3 -41 44 -65 62 -31 113 6 67 48 -23 21 -90 31
-111 17z"/>
<path d="M10130 7358 c-29 -11 -36 -19 -38 -45 -3 -27 2 -34 29 -48 43 -23
200 -18 225 7 21 21 12 63 -17 79 -32 17 -159 21 -199 7z"/>
<path d="M10102 5318 c-37 -37 2 -96 93 -140 67 -34 98 -40 118 -25 51 39 -6
115 -121 160 -50 20 -74 21 -90 5z"/>
<path d="M7675 4634 c-5 -44 -4 -69 4 -77 8 -8 11 -3 11 19 0 53 10 75 32 68
17 -5 18 -3 9 15 -6 11 -20 25 -30 31 -18 9 -20 5 -26 -56z"/>
<path d="M8410 4848 c0 -7 -3 -24 -7 -37 l-6 -24 64 7 c64 7 106 26 59 26 -41
0 -70 11 -70 26 0 8 -9 14 -20 14 -11 0 -20 -6 -20 -12z"/>
<path d="M8248 4799 c-25 -14 -22 -27 13 -60 40 -37 59 -37 59 0 0 24 6 31 33
41 29 11 30 13 12 20 -28 12 -97 11 -117 -1z"/>
<path d="M8600 4721 c0 -11 4 -22 9 -25 11 -7 23 22 15 35 -9 16 -24 10 -24
-10z"/>
<path d="M5830 4710 c0 -5 5 -10 10 -10 6 0 10 -7 10 -15 0 -8 5 -15 10 -15
14 0 13 23 -2 38 -15 15 -28 16 -28 2z"/>
<path d="M4025 4799 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"/>
<path d="M4033 4776 c-25 -8 -39 -24 -67 -77 -45 -84 -46 -102 -8 -93 38 9 42
13 42 35 0 29 28 90 52 116 26 28 22 31 -19 19z"/>
<path d="M4940 4661 c0 -14 8 -18 37 -19 39 -2 36 7 -9 26 -25 10 -28 9 -28
-7z"/>
<path d="M2391 4514 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2880 3963 c0 -4 14 -24 31 -43 17 -19 39 -46 49 -60 10 -14 21 -22
24 -19 3 3 -2 15 -12 28 -31 39 -92 102 -92 94z"/>
<path d="M8498 4353 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
